import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

import MEDIA from '../../mediaTemplates';
import { ButtonSecondary } from '../Buttons';
import Observer from '../Observer';
import LogoSvg from '../../images/logo.svg';

const Container = styled.header`
  padding: 60px 0;

  ${MEDIA.PHONE`
    padding: 20px 0;
  `};
`;

const Logo = styled(LogoSvg)`
  ${MEDIA.PHONE`
    width: 130px;
  `};
`;

const Nav = styled.nav`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s ease-in-out all;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    ${MEDIA.PHONE`
      padding: 0 20px;
      justify-content: space-between;
      flex-direction: row-reverse;
    `};
  }

  li {
    padding: 0 35px;

    ${MEDIA.PHONE`
      padding: 0;

      &:nth-child(3) {
        display: none;
        p
      }
    `};
  }
`;

const Banner = styled.div`
  background-color: #0c2b47;
  color: #fff;
  width: 100%;
  padding: 4rem;
  text-align: center;
  font-weight: 500;
  overflow: hidden;
  position: relative;

  & > div {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    user-select: none;
    pointer-events: none;
    opacity: 0.6;
  }

  aside {
    position: relative;
    z-index: 1;
  }

  a {
    color: currentColor;
  }
`;

const Header = ({ data, absolute }) => (
  <>
    <Container>
      <Observer>
        {({ hasBeenVisible }) => (
          <Nav isVisible={hasBeenVisible}>
            <ul>
              <li>
                {absolute ? (
                  <Link to="/#info">
                    <ButtonSecondary as="span">Über die Villa</ButtonSecondary>
                  </Link>
                ) : (
                  <ScrollLink
                    to="info"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    <ButtonSecondary as="span">Über die Villa</ButtonSecondary>
                  </ScrollLink>
                )}
              </li>
              <li>
                <Link to="/">
                  <Logo />
                </Link>
              </li>
              <li>
                <ButtonSecondary as={Link} to="/rooms">
                  Zimmeranfrage
                </ButtonSecondary>
              </li>
            </ul>
          </Nav>
        )}
      </Observer>
    </Container>
  </>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        fan: file(relativePath: { regex: "/fan/" }) {
          childImageSharp {
            fixed(height: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);
