import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { H3 } from '../Common';
import { COLORS } from '../../theme';
import MEDIA from '../../mediaTemplates';

const Footer = styled.footer`
  font-size: 1.6rem;
  line-height: 1.4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: rgba(0, 0, 0, 0.03);

  ${MEDIA.TABLET`
    grid-template-columns: 1fr;
 `};

  ${MEDIA.PHONE`
    text-align: center;
  `};
`;

const FooterInner = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px;
  display: flex;
  color: ${COLORS.TEXT_TERTIARY};
  align-self: flex-end;

  ${MEDIA.PHONE`
    display: block;
  `};

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Left = styled.div``;

const Right = styled.div`
  text-align: right;

  ${MEDIA.PHONE`
    text-align: center;
  `};
`;

const Nav = styled.nav`
  color: ${COLORS.TEXT_SECONDARY};
  margin-bottom: 10px;

  a {
    display: inline-block;
    padding: 3px 0;

    ${MEDIA.PHONE`
      padding: 10px 0;
    `};
  }
`;

const Image = styled(GatsbyImage)`
  max-height: 25vw;
  height: 100%;

  ${MEDIA.TABLET`
    min-height: 0;
    height: 33vw;
 `};
`;

const Address = styled.div`
  color: ${COLORS.TEXT_SECONDARY};
  margin-top: 10px;
  margin-bottom: 10px;

  ${MEDIA.PHONE`
    margin-bottom: 24px;
    margin-bottom: 0;
  `};
`;

export default ({ image, address }) => (
  <>
    <Footer>
      <Image fluid={image.localFile.childImageSharp.fluid}></Image>
      <FooterInner marginBottom>
        <Left>
          <H3 color={COLORS.TEXT_PRIMARY}>Villa Geriwada</H3>
          <Address dangerouslySetInnerHTML={{ __html: address }} />
          <a href="mailto:info@villa-geriwada.de">info@villa-geriwada.de</a>
        </Left>

        <Right>
          <Nav>
            <ul>
              <li>
                <Link to="/cancel">Stornierung</Link>
              </li>
              <li>
                <a href="/datenschutz">Datenschutz</a>
              </li>
              <li>
                <a href="/impressum">Impressum</a>
              </li>
            </ul>
          </Nav>
          <p>&copy; {new Date().getFullYear()} SchlossHOTELGedern</p>
        </Right>
      </FooterInner>
    </Footer>
  </>
);
