import styled from 'styled-components';

import { COLORS } from '../../theme';
import MEDIA from '../../mediaTemplates';

export const ButtonSecondary = styled.a`
  display: inline-block;
  color: ${COLORS.TEXT_PRIMARY};
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  @media (-moz-touch-enabled: 0), (pointer: fine) {
    &:hover {
      &::after {
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 50%;
      }
    }
  }

  &::after {
    display: ${p => (p.hideBorder ? 'none' : 'block')};
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    height: 2px;
    background-color: ${p => (p.light ? '#fff' : '#78715c')};
    opacity: 0.3;
    content: '';
    transform: scaleX(1);
    transform-origin: 0% 50%;
    transition: transform 250ms ease-in-out;
  }
`;

export const ButtonPrimary = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px 25px 16px;
  color: #fff;
  background-color: ${COLORS.ACCENT};
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.6rem;
  box-shadow: inset 0 0 0 1px ${COLORS.BACKGROUND};
  border: 1px solid ${COLORS.ACCENT};
  letter-spacing: 0.9px;
  margin-right: ${({ marginRight }) => (marginRight ? 20 : 0)}px;
  font-weight: 500;
  width: ${({ full }) => (full ? '100%' : 'auto')};
  text-align: center;
  position: relative;
  transform: translateZ(0);
  transition: 400ms color ease-in-out;
  cursor: pointer;
  margin-top: ${p => (p.marginTop ? p.marginTop : 0)}px;
  line-height: 1;
  font-family: inherit;

  svg {
    fill: #fff;
    height: 20px;
    transition: 400ms fill ease-in-out;
    margin-right: 8px;
    padding-bottom: 4px;
    opacity: 0.6;
  }

  @media (-moz-touch-enabled: 0), (pointer: fine) {
    &:hover {
      color: ${COLORS.TEXT_PRIMARY};
      border-color: ${COLORS.TEXT_PRIMARY};

      svg {
        fill: ${COLORS.TEXT_PRIMARY};
      }

      &::before {
        background-color: ${COLORS.BACKGROUND_DIMMED};
        transform: scaleX(1);
        transform-origin: 0% 0;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${COLORS.BACKGROUND_DIMMED};
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: 400ms ease-in-out;
  }

  ${MEDIA.PHONE`
    width: 100%;
    display: flex;

    & + ${ButtonSecondary} {
      margin: 16px auto 0;
    }
 `};
`;
