import React from 'react';
import { Helmet } from 'react-helmet';
import { COLORS } from '../../theme';

export default ({
  siteTitle = 'Villa Geriwada',
  siteDescription = 'Unsere Villa Geriwada befindet sich unmittelbar oberhalb des Gederner Schlossareals, nur 2 Minuten vom SchlossHOTELGedern entfernt und verfügt über 5 komfortable Doppelzimmer, alle mit herrlichem Blick.',
  siteUrl = 'https://villa-geriwada.de',
  pageUrl = '',
  canonical = `${siteUrl}${pageUrl}`,
  pageTitle,
  pageTitleFull = pageTitle ? `${pageTitle} - ${siteTitle}` : siteTitle,
  themeColor = COLORS.BACKGROUND,
  imageUrl,
}) => (
  <Helmet>
    <html lang="de" />

    <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
    <meta
      content="width=device-width,initial-scale=1.0,user-scalable=yes"
      name="viewport"
    />

    <meta content={siteTitle} name="apple-mobile-web-app-title" />
    <meta content={pageTitleFull} property="og:title" />
    <meta content={pageTitleFull} name="twitter:title" />
    <title>{pageTitleFull}</title>

    <meta content={siteDescription} name="description" />
    <meta content={siteDescription} property="og:description" />
    <meta content={siteDescription} name="twitter:description" />

    <meta content="yes" name="apple-mobile-web-app-capable" />
    <meta
      content="black-translucent"
      name="apple-mobile-web-app-status-bar-style"
    />
    <meta content={themeColor} name="theme-color" />
    <meta content={siteTitle} name="application-name" />

    <meta content="website" property="og:type" />
    <meta content={siteTitle} property="og:site_name" />
    <meta content="summary_large_image" name="twitter:card" />
    <meta content={pageTitleFull} name="twitter:text:title" />
    <meta content={canonical} property="og:url" />
    <meta content={canonical} name="twitter:url" />
    <link rel="canonical" href={canonical} />

    <meta content={imageUrl || `${siteUrl}/social.png`} property="og:image" />
    <meta content="1024" property="og:image:width" />
    <meta content="512" property="og:image:height" />
    <meta content={imageUrl || `${siteUrl}/social.png`} name="twitter:image" />
    <meta content="1024" name="twitter:image:width" />
    <meta content="512" name="twitter:image:height" />
    <meta content={imageUrl || `${siteUrl}/social.png`} property="og:image" />
    <meta content="1024" property="og:image:width" />
    <meta content="512" property="og:image:height" />

    <meta content={themeColor} name="msapplication-TileColor" />
    <meta content="/icons/mstile-70x70.png" name="msapplication-square70x70" />
    <meta
      content="/icons/mstile-144x144.png"
      name="msapplication-square144x144"
    />
    <meta
      content="/icons/mstile-150x150.png"
      name="msapplication-square150x150"
    />
    <meta
      content="/icons/mstile-310x150.png"
      name="msapplication-wide310x150"
    />
    <meta
      content="/icons/mstile-310x310.png"
      name="msapplication-square310x310"
    />

    <link href="/manifest.json" rel="manifest" />

    <link
      href="/icons/apple-touch-icon-57x57.png"
      rel="apple-touch-icon"
      sizes="57x57"
    />
    <link
      href="/icons/apple-touch-icon-60x60.png"
      rel="apple-touch-icon"
      sizes="60x60"
    />
    <link
      href="/icons/apple-touch-icon-72x72.png"
      rel="apple-touch-icon"
      sizes="72x72"
    />
    <link
      href="/icons/apple-touch-icon-76x76.png"
      rel="apple-touch-icon"
      sizes="76x76"
    />
    <link
      href="/icons/apple-touch-icon-114x114.png"
      rel="apple-touch-icon"
      sizes="114x114"
    />
    <link
      href="/icons/apple-touch-icon-120x120.png"
      rel="apple-touch-icon"
      sizes="120x120"
    />
    <link
      href="/icons/apple-touch-icon-144x144.png"
      rel="apple-touch-icon"
      sizes="144x144"
    />
    <link
      href="/icons/apple-touch-icon-152x152.png"
      rel="apple-touch-icon"
      sizes="152x152"
    />
    <link
      href="/icons/apple-touch-icon-167x167.png"
      rel="apple-touch-icon"
      sizes="167x167"
    />
    <link
      href="/icons/apple-touch-icon-180x180.png"
      rel="icon"
      sizes="180x180"
      type="image/png"
    />

    <link
      href="/icons/favicon-32x32.png"
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href="/icons/favicon-16x16.png"
      rel="icon"
      sizes="16x16"
      type="image/png"
    />

    <link rel="preload" href="/fonts/worksans-500.woff2" as="font" />
    <link rel="preload" href="/fonts/39CDA0_0_0.woff2" as="font" />
    <link rel="preload" href="/fonts/worksans-400.woff2" as="font" />

    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'Hotel',
        name: 'Villa Geriwada',
        url: 'https://villa-geriwada.de/',
        logo: 'https://villa-geriwada.de/social.png',
        image: 'https://villa-geriwada.de/social.png',
        description:
          'Unsere Villa Geriwada befindet sich unmittelbar oberhalb des Gederner Schlossareals, nur 2 Minuten vom SchlossHOTELGedern entfernt und verfügt über 7 komfortable Doppelzimmer, alle mit herrlichem Blick.',
        sameAs: 'https://www.facebook.com/schlosshotel.gedern',
        telephone: '0604506150',
        email: 'info@villa-geriwada.de',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Schlossberg 15',
          addressLocality: 'Gedern',
          addressRegion: 'Hessen',
          postalCode: '63688',
          addressCountry: 'Germany',
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: '50.42366939999999',
          longitude: '9.204301699999974',
        },
        hasMap:
          'https://www.google.com/maps/place/50.42366939999999,9.204301699999974',
        availableLanguage: [
          { '@type': 'Language', name: 'German' },
          { '@type': 'Language', name: 'English' },
        ],
        priceRange: '$$',
        starRating: {
          '@type': 'http://schema.org/Rating',
          ratingValue: '4',
        },
        petsAllowed: 'False',
        smokingAllowed: 'False',
        makesOffer: {
          '@type': 'Offer',
          name: 'Doppelzimmer',
          description: 'Übernachten im romantischen Landhausstil',
          validThrough: '2030-01-01',
          priceSpecification: {
            '@type': 'UnitPriceSpecification',
            price: '78',
            priceCurrency: 'EUR',
            unitCode: 'DAY',
          },
          acceptedPaymentMethod: [
            {
              '@type': 'PaymentMethod',
              url: 'http://purl.org/goodrelations/v1#Cash',
            },
            {
              '@type': 'PaymentMethod',
              url: 'http://purl.org/goodrelations/v1#MasterCard',
            },
            {
              '@type': 'PaymentMethod',
              url: 'http://purl.org/goodrelations/v1#VISA',
            },
          ],
        },
        amenityFeature: [
          { '@type': 'LocationFeatureSpecification', name: 'Free Wi-Fi' },
          { '@type': 'LocationFeatureSpecification', name: 'Free parking' },
          { '@type': 'LocationFeatureSpecification', name: 'Laundry service' },
          { '@type': 'LocationFeatureSpecification', name: 'Kid-friendly' },
          { '@type': 'LocationFeatureSpecification', name: 'Restaurant' },
          { '@type': 'LocationFeatureSpecification', name: 'Bar' },
          { '@type': 'LocationFeatureSpecification', name: 'Smoke-free' },
        ],
      })}
    </script>
  </Helmet>
);
