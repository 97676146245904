import styled from 'styled-components';

import { COLORS } from '../../theme';
import MEDIA from '../../mediaTemplates';

export const H1 = styled.h1`
  font-family: 'VeronaSerial', serif;
  font-size: 5.8rem;
  line-height: 1.06;
  color: ${COLORS.TEXT_PRIMARY};
  margin-bottom: 24px;
  /* letter-spacing: -1px; */

  ${MEDIA.PHONE`
    font-size: 4rem;
  `};
`;

export const H3 = styled.h3`
  font-weight: 500;
  font-size: 1.6rem;
  color: ${COLORS.TEXT_TERTIARY};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? 28 : 0)}px;
  color: ${({ color }) => color && color};

  ${MEDIA.PHONE`
    margin-bottom: ${({ marginBottom }) => (marginBottom ? 16 : 0)}px;
  `};
`;

export const Hr = styled.hr`
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  margin: ${({ marginTop, marginBottom }) =>
    `${marginTop !== undefined ? marginTop : 80}px 0 ${
      marginBottom !== undefined ? marginBottom : 80
    }px`};

  ${MEDIA.PHONE`
    margin: ${({ marginTop, marginBottom }) =>
      `${marginTop !== undefined ? marginTop : 40}px 0 ${
        marginBottom !== undefined ? marginBottom : 40
      }px`};
  `};
`;

export const Wrapper = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;
